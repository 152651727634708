import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import { clearTokens } from '../../services/token-service';
import style from './style.css';

const Header: FunctionalComponent = () => {

    const handleLogout = () => {
        clearTokens();
        window.location.reload();
    }

    return (
        <header class={style.header}>
            <h1>Auth Server</h1>
            <nav>
                <Link activeClassName={style.active} href="/">
                    Dashboard
                </Link>
                <Link activeClassName={style.active} href="/clients">
                    Clients
                </Link>
                <Link activeClassName={style.active} href="/accounts">
                    Accounts
                </Link>
                <button class="text-white mr-4 ml-8" onClick={handleLogout}>
                    logout
                </button>
            </nav>
        </header>
    );
};

export default Header;
