import { Fragment, FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';

import NotFoundPage from '../routes/notfound';
import Header from './header';
import Clients from '../routes/clients';
import Accounts from '../routes/accounts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isAuthorized } from '../services/token-service';
import Login from '../routes/login';
import Dashboard from '../routes/dashboard';

const App: FunctionalComponent = () => {
    if (!isAuthorized()) {

    }

    return (
        <div id="preact_root" class='bg-gray-800'>
            { isAuthorized()
            ? 
            <>
                <Header />
                <Router>
                    <Route path="/" component={Dashboard} />
                    <Route path="/clients/" component={Clients} />
                    <Route path="/accounts/" component={Accounts} />
                    <NotFoundPage default />
                </Router>
            </>
            : <Login/>
            }
            
            <ToastContainer
                position='top-right'
                autoClose={4000}
                pauseOnHover
                theme='dark'
            />
        </div>
    );
};

export default App;
